<template>
  <div class="login">
    <LoadingSpinner :isLoading="isLoading" />
    <div class="min-h-screen bg-primary-300 bg-opacity-50 flex items-center justify-center">
      <div class="bg-white lg:w-1/3 p-8 md:p-12 rounded-lg shadow-xl">
        <div>
          <router-link to="/">
            <img class="logo w-40 mx-auto" :src="require('@/assets/logos/logo_horizontal.png')" />
          </router-link>
        </div>
        <div class="mt-8">
          <main v-if="!passwordSetSuccess">
            <section class="text-center">
              <h3 class="font-bold text-2xl">{{ $t("change_password_modal.change_password") }}</h3>
            </section>

            <section class="mt-8">
              <ValidationObserver ref="formSetPassword">
                <div class="flex flex-col">
                  <div class="">
                    <label class="text-gray-600 text-sm font-bold pb-2 font-serif">{{ $t("change_password_modal.username") }}<b class="text-red-500 ml-1">*</b></label>
                    <ValidationProvider :name="$t('change_password_modal.username')" rules="required" v-slot="{ classes, errors }">
                      <div class="input-validate" :class="classes">
                        <input type="text" v-model="username" class="bg-gray-100 rounded w-full text-sm text-gray-900 focus:outline-none border-b-4 border-gray-300 transition duration-500 px-3 pt-2 pb-2" />
                        <span>{{ errors[0] }}</span>
                      </div>
                    </ValidationProvider>
                  </div>
                  <div class="">
                    <label class="text-gray-600 text-sm font-bold pb-2 font-serif">{{ $t("change_password_modal.password") }}<b class="text-red-500 ml-1">*</b></label>
                    <ValidationProvider :name="$t('change_password_modal.password')" rules="required" v-slot="{ classes, errors }" vid="password">
                      <div class="input-validate" :class="classes">
                        <input type="password" v-model="password" class="bg-gray-100 rounded w-full text-sm text-gray-900 focus:outline-none border-b-4 border-gray-300 transition duration-500 px-3 pt-2 pb-2" />
                        <span>{{ errors[0] }}</span>
                      </div>
                    </ValidationProvider>
                  </div>
                  <div class="">
                    <label class="text-gray-600 text-sm font-bold pb-2 font-serif">{{ $t("change_password_modal.confirm_password") }}<b class="text-red-500 ml-1">*</b></label>
                    <ValidationProvider :name="$t('change_password_modal.confirm_password')" rules="required|confirmed:password" v-slot="{ classes, errors }">
                      <div class="input-validate" :class="classes">
                        <input type="password" v-model="confirmPassword" class="bg-gray-100 rounded w-full text-sm text-gray-900 focus:outline-none border-b-4 border-gray-300 transition duration-500 px-3 pt-2 pb-2" />
                        <span>{{ errors[0] }}</span>
                      </div>
                    </ValidationProvider>
                  </div>
                  <button class="btn-blue px-2 py-3 text-sm mt-5" @click.prevent="changePassword()">
                    {{ $t("change_password_modal.change_password") }}
                    <BaseIcon icon="key" class="ml-2" />
                  </button>
                </div>
              </ValidationObserver>
            </section>
          </main>
          <main v-if="passwordSetSuccess">
            <section class="text-center">
              <p class="text-gray-600 pt-2 text-lg">
                {{ $t("change_password_modal.change_password_success") }} <router-link class="link" to="/">{{ $t("change_password_modal.link") }}</router-link
                >.
              </p>
            </section>
          </main>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ResetPassword",
  title() {
    return this.$t("page_titles.change_password");
  },
  data() {
    return {
      isLoading: false,
      user_id: this.$route.params.id,
      reset_token: this.$route.params.token,
      username: "",
      password: "",
      confirmPassword: "",
      passwordSetSuccess: false,
    };
  },

  methods: {
    async changePassword() {
      this.$refs.formSetPassword.validate().then(async (success) => {
        if (!success) {
          return;
        }

        try {
          let data = {
            username: this.username,
            password: this.password,
            user_id: this.user_id,
            reset_token: this.reset_token,
          };

          this.isLoading = true;
          let response = await this.axios.post(`${process.env.VUE_APP_SERVER_URL}/auth/reset_password`, data);
          this.isLoading = false;

          if (response.data.error) this.handleError(null, response.data.message);
          if (response.data.success) this.passwordSetSuccess = true;
        } catch (error) {
          this.isLoading = false;
          this.handleError(error);
        }
      });
    },
  },
  watch: {
    "$i18n.locale"() {
      document.title = this.$t("page_titles.change_password");
    },
  },
};
</script>
