var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"login"},[_c('LoadingSpinner',{attrs:{"isLoading":_vm.isLoading}}),_c('div',{staticClass:"min-h-screen bg-primary-300 bg-opacity-50 flex items-center justify-center"},[_c('div',{staticClass:"bg-white lg:w-1/3 p-8 md:p-12 rounded-lg shadow-xl"},[_c('div',[_c('router-link',{attrs:{"to":"/"}},[_c('img',{staticClass:"logo w-40 mx-auto",attrs:{"src":require('@/assets/logos/logo_horizontal.png')}})])],1),_c('div',{staticClass:"mt-8"},[(!_vm.passwordSetSuccess)?_c('main',[_c('section',{staticClass:"text-center"},[_c('h3',{staticClass:"font-bold text-2xl"},[_vm._v(_vm._s(_vm.$t("change_password_modal.change_password")))])]),_c('section',{staticClass:"mt-8"},[_c('ValidationObserver',{ref:"formSetPassword"},[_c('div',{staticClass:"flex flex-col"},[_c('div',{},[_c('label',{staticClass:"text-gray-600 text-sm font-bold pb-2 font-serif"},[_vm._v(_vm._s(_vm.$t("change_password_modal.username"))),_c('b',{staticClass:"text-red-500 ml-1"},[_vm._v("*")])]),_c('ValidationProvider',{attrs:{"name":_vm.$t('change_password_modal.username'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var classes = ref.classes;
var errors = ref.errors;
return [_c('div',{staticClass:"input-validate",class:classes},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.username),expression:"username"}],staticClass:"bg-gray-100 rounded w-full text-sm text-gray-900 focus:outline-none border-b-4 border-gray-300 transition duration-500 px-3 pt-2 pb-2",attrs:{"type":"text"},domProps:{"value":(_vm.username)},on:{"input":function($event){if($event.target.composing){ return; }_vm.username=$event.target.value}}}),_c('span',[_vm._v(_vm._s(errors[0]))])])]}}],null,false,302876144)})],1),_c('div',{},[_c('label',{staticClass:"text-gray-600 text-sm font-bold pb-2 font-serif"},[_vm._v(_vm._s(_vm.$t("change_password_modal.password"))),_c('b',{staticClass:"text-red-500 ml-1"},[_vm._v("*")])]),_c('ValidationProvider',{attrs:{"name":_vm.$t('change_password_modal.password'),"rules":"required","vid":"password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var classes = ref.classes;
var errors = ref.errors;
return [_c('div',{staticClass:"input-validate",class:classes},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.password),expression:"password"}],staticClass:"bg-gray-100 rounded w-full text-sm text-gray-900 focus:outline-none border-b-4 border-gray-300 transition duration-500 px-3 pt-2 pb-2",attrs:{"type":"password"},domProps:{"value":(_vm.password)},on:{"input":function($event){if($event.target.composing){ return; }_vm.password=$event.target.value}}}),_c('span',[_vm._v(_vm._s(errors[0]))])])]}}],null,false,4034184754)})],1),_c('div',{},[_c('label',{staticClass:"text-gray-600 text-sm font-bold pb-2 font-serif"},[_vm._v(_vm._s(_vm.$t("change_password_modal.confirm_password"))),_c('b',{staticClass:"text-red-500 ml-1"},[_vm._v("*")])]),_c('ValidationProvider',{attrs:{"name":_vm.$t('change_password_modal.confirm_password'),"rules":"required|confirmed:password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var classes = ref.classes;
var errors = ref.errors;
return [_c('div',{staticClass:"input-validate",class:classes},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.confirmPassword),expression:"confirmPassword"}],staticClass:"bg-gray-100 rounded w-full text-sm text-gray-900 focus:outline-none border-b-4 border-gray-300 transition duration-500 px-3 pt-2 pb-2",attrs:{"type":"password"},domProps:{"value":(_vm.confirmPassword)},on:{"input":function($event){if($event.target.composing){ return; }_vm.confirmPassword=$event.target.value}}}),_c('span',[_vm._v(_vm._s(errors[0]))])])]}}],null,false,2528140594)})],1),_c('button',{staticClass:"btn-blue px-2 py-3 text-sm mt-5",on:{"click":function($event){$event.preventDefault();return _vm.changePassword()}}},[_vm._v(" "+_vm._s(_vm.$t("change_password_modal.change_password"))+" "),_c('BaseIcon',{staticClass:"ml-2",attrs:{"icon":"key"}})],1)])])],1)]):_vm._e(),(_vm.passwordSetSuccess)?_c('main',[_c('section',{staticClass:"text-center"},[_c('p',{staticClass:"text-gray-600 pt-2 text-lg"},[_vm._v(" "+_vm._s(_vm.$t("change_password_modal.change_password_success"))+" "),_c('router-link',{staticClass:"link",attrs:{"to":"/"}},[_vm._v(_vm._s(_vm.$t("change_password_modal.link")))]),_vm._v(". ")],1)])]):_vm._e()])])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }